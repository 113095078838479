.disclaimer-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  animation: fade-in 0.5s forwards;
}

.disclaimer-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  color: #000;
  animation: slide-up 0.5s forwards;
}

.footer-logo {
  margin-top: 50px;
  width: 600px;
  height: auto;
}
