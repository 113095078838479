.valuation-calculator {
  font-family: "Arial", sans-serif;
  width: 400px;
  justify-content: center; /* Center the container horizontally */
  padding: 20px;
  background-color: transparent;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.calculator-heading {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.startup-type-buttons {
  margin-top: 20px;
}

.button-label {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.startup-type-button {
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 16px;
  margin-right: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.startup-type-button.active {
  background-color: aqua;
  color: #fff;
}

.growth-rate-buttons {
  margin-top: 10px;
}

.growth-rate-button {
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  padding: 6px 12px;
  margin-right: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.growth-rate-button.active {
  background-color: aqua;
  color: #fff;
}

.metric-inputs {
  margin-top: 20px;
}

.metric-input {
  margin-bottom: 10px;
}

.input-label {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: white;
  color: #fff;
}

.calculate-button {
  margin-top: 20px;
  border: none;
  background-color: aqua;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calculate-button:hover {
  background-color: #9c0c5a;
}

.valuation-result {
  margin-top: 20px;
  text-align: center;
}

.result-text {
  font-size: 16px;
  font-weight: bold;
  color: antiquewhite;
  margin-bottom: 10px;
}

.disclaimer-text {
  margin-top: 20px;
  font-size: 12px;
  color: #a8a8a8;
}

.info-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #9c0c5a;
  color: #212529;
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.3s;
  position: relative; /* To create a new context for absolute positioning of child elements */
}

.info-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: blue;
  color: #fff;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.3s;
}

.info-button:hover {
  opacity: 1;
}

.info-button .info-tooltip {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  color: #111;
  padding: 10px;
  border-radius: 4px;
  width: 200px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.info-button:hover .info-tooltip {
  display: block;
}

@media (max-width: 480px) {
  .valuation-calculator {
    width: 100%;
  }
}

.close-button {
  position: absolute;
  top: 100px;
  right: 10px;
  border: none;
  background-color: transparent;
  color: rgb(243, 11, 11);
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.close-button:hover {
  color: rgb(233, 3, 3);
}
.info-button-container {
  display: inline-block;
  position: relative;
}

.info-button-tooltip {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  color: #111;
  padding: 10px;
  border-radius: 4px;
  width: 200px;
  top: -120%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition:
    opacity 0.3s,
    visibility 0.3s;
  z-index: 1;
}

.info-button-container:hover .info-button-tooltip {
  display: block;
}
