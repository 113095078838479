body {
  font-family: Montserrat, sans-serif;
  padding: 10px;
  background-color: #5000ca; /* Initial deep purple color */
  color: #fff;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  animation: colorChange 20s linear infinite alternate; /* 20s duration for the animation */
}

.ycsafe {
  text-align: center; /* Center-align the text */
  font-family: "Montserrat", sans-serif; /* Use the Montserrat font */
}

@keyframes colorChange {
  0% {
    background-color: #5000ca; /* Deep purple */
  }
  10% {
    background-color: #5400d4; /* Shade 1 */
  }
  20% {
    background-color: #5b00e0; /* Shade 2 */
  }
  30% {
    background-color: #6600ee; /* Shade 3 */
  }
  40% {
    background-color: #7500ff; /* Shade 4 */
  }
  50% {
    background-color: #850eff; /* Shade 5 */
  }
  60% {
    background-color: #960dff; /* Shade 6 */
  }
  70% {
    background-color: #a80afd; /* Shade 7 */
  }
  80% {
    background-color: #bb18fa; /* Shade 8 */
  }
  90% {
    background-color: #cf27f7; /* Shade 9 */
  }
  100% {
    background-color: #e137f4; /* Deep blue */
  }
}

h2 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Input Styles */
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-section.safe-round h3 {
  text-align: center;
}
.input-section.safe-round {
  background-color: transparent;

  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  width: 80%;
}

.input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10%;
  width: 100%;
}

.input-label-safe {
  margin-right: 20px;
  font-size: 14px;
}

.card-container {
  display: flex;
  justify-content: center; /* Center the cards */
}

.card {
  width: 80px;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Make the border less visible */
  border-radius: 12px; /* Increase rounded corners for a softer, more modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow for 'pop' effect */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  position: relative;
  background: transparent;
  transition: all 0.3s ease; /* Animation for smooth transitions */
}

.card h4 {
  font-size: 12px;
  font-family: "Courier New", Courier, monospace; /* Use a techy font */
}

/* Updated styles for selected state */
.card.selected {
  border-color: rgba(0, 123, 255, 0.9); /* Highlighted border color */
  border-width: 4px; /* Increase border width */
  box-shadow: 0 6px 20px rgba(0, 123, 255, 0.5); /* Enhanced, colored shadow */
}

.hover-info {
  display: none;
  position: absolute;
  right: 110%;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(
    90deg,
    rgba(0, 123, 255, 1) 0%,
    rgba(9, 9, 121, 1) 100%
  ); /* Gradient background */
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px; /* Increase border radius */
  padding: 12px; /* Slightly more padding */
  width: 200px;
  font-size: 12px;
  color: white; /* White font */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Drop shadow for 'pop' effect */
}

.card:hover .hover-info {
  display: block;
}

.input-column {
  flex-direction: column;
}

.input-label {
  align-items: center;
  justify-content: center;
}
/* Center the input fields under their labels */
.input-section.safe-round .input-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50%;
}

/* Styles for the container */
.input-row-advanced {
  display: flex;
  flex-direction: column; /* Stack the items vertically */
  align-items: center; /* Center the content horizontally */
  text-align: center; /* Center the text within the button */
  margin-top: 10px; /* Add some space above the container */
}

/* Styles for the button container */
.advanced-button-container {
  margin-bottom: 10px; /* Add some space below the button */
}

.input-field.safe {
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  width: 50%;
  background-color: transparent;
  transition: border-color 0.3s;
}

/* Style the input fields within the input-table */
.input-table input {
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s;
  background-color: transparent; /* Set the initial background color to transparent */
}

/* Style the input fields with a white background when focused (selected) */
.input-table input:focus {
  background-color: white;
  color: blue;
  border-color: #007bff; /* Change the border color on focus, if desired */
}

/* Add additional styles to target specific input fields if necessary */

.input-button-calculate {
  display: block;
  margin: 0 auto; /* This centers the button horizontally */
  border: none;
  background-color: blue;
  color: white;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.input-button-calculate:hover {
  background-color: #9c0c5a;
}

.input-unit-label {
  font-size: 12px;
  margin-right: 8px;
}

.label-text {
  font-size: 12px;
  font-weight: lighter;
}

.input-field.safe:focus {
  outline: none;
  border-color: #208498;
}

.error-field {
  border-color: #f00;
}

.info-tooltip {
  font-size: 12px;
  color: antiquewhite;
  margin-top: 5px;
}

/* Add any additional CSS styles as needed */

/* Style for column headings */

/* Style for input fields */

/* Style for error field (if needed) */
.error-field {
  border-color: #f00;
}

/* Table Styles */
table {
  width: 80%;
  border-collapse: collapse;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

th,
td {
  padding: 8px;
  border-bottom: 1px solid #fff;
  text-align: left;
}

/* Button Styles */
button {
  border: none;
  background-color: blue;
  color: white;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #9c0c5a;
}

/* Popup Styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: transparent;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.popup-content {
  background-color: #212529;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  font-size: 14px;
  line-height: 1.5;
}

.popup-content span {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #9c0c5a;
  margin-bottom: 10px;
}

/* Chart Styles */
.chart-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.pie-chart-wrapper {
  display: flex;
  justify-content: center;
  background-color: transparent;
}

.pie-chart-container {
  flex: 1;
  margin-right: 20px;
  background-color: transparent;
}

.chart-legend {
  flex: 1;
}

.chart-legend h3 {
  font-size: 18px;
  font-weight: bold;
  color: #9c0c5a;
  margin-bottom: 10px;
}

.chart-legend table {
  width: 100%;
  border-collapse: collapse;
}

.chart-legend th,
.chart-legend td {
  padding: 8px;
  border-bottom: 1px solid #fff;
  text-align: left;
}

.chart-legend th {
  font-weight: bold;
}

.chart-legend td:last-child {
  text-align: right;
}

/* Additional Styles */
.rv-radial-chart-text {
  pointer-events: none;
}

.new-cap-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.hovered-row {
  background-color: rgb(107, 111, 163);
  transition: background-color 0.3s;
}

.hovered-row:hover {
  background-color: rgb(117, 121, 173);
}

/* Footer Styles */
.footer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #fff;
}

/* Resource Styles */
.resources {
  margin-bottom: 10px;
}

.resources h3 {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.resources ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.resources li {
  margin-bottom: 5px;
}

/* Media Queries for Smaller Screens (Mobile) */
@media (max-width: 768px) {
  /* Adjust styles for screens with a maximum width of 768px (e.g., mobile devices) */

  /* General Styles */
  body {
    padding: 5px;
    overflow-x: auto; /* Add horizontal scrolling if the table exceeds the container */

    background-color: #5000ca;
  }

  /* Header Styles */
  h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  /* YC Safe Styles */
  .ycsafe {
    text-align: center;
  }

  /* Keyframes Animation */
  @keyframes colorChange {
    /* No changes needed for keyframes animation */
  }
  input-group {
    width: 90%;
  }
  .centered-container {
    width: 100%;
    padding-left: 5px; /* Reduce left padding for mobile */
    padding-right: 5px; /* Reduce right padding for mobile */
  }

  /* Input Row Styles for Mobile */
  .input-row {
    width: 100%; /* Ensure the container takes up 100% width of the viewport */
    margin-bottom: 10px; /* Add some space below the table container */
    display: flex; /* Use flexbox to organize the container */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally within the container */
  }

  /* Ensure that input fields do not overlap */
  .input-row input[type="text"],
  .input-row input[type="number"] {
    width: 100%; /* Make input fields take up 100% width within the container */
    max-width: 300px; /* Optionally set a max-width if you don't want too wide fields */
    box-sizing: border-box; /* Include padding and border within the width */
    margin-bottom: 10px; /* Add some space below each input field for better readability */
    display: block; /* Force each input field to take up its own line */
  }

  .input-label-safe {
    font-size: 14px;
  }
  .input-table {
    width: 90%;
  }
  /* Card Styles */
  .card {
    width: 60px;
    height: 25px;
    margin: 0 5px;
  }

  .card h4 {
    font-size: 12px;
  }

  /* Updated styles for selected state */
  .card.selected {
    border-width: 4px;
  }

  /* Hover Info Styles */
  .hover-info {
    right: auto;
    left: 0;
    top: -5px;
    transform: none;
    width: 50%;
    padding: 8px;
  }

  .input-column {
    width: 30%; /* Make the input column take up 100% width for mobile screens */
    display: inline-block; /* Display columns inline */
    margin-right: 20px; /* Add right margin to create spacing between columns */
    vertical-align: top; /* Align columns vertically at the top */
  }

  .input-label {
    width: 50%;
    font-size: 12px;
  }

  /* Input Field Styles */

  .input-field.safe {
    padding: 8px;
    font-size: 14px;
    width: 100%; /* Full width for input fields on mobile */
  }

  /* Button Styles */
  button {
    font-size: 12px;
    padding: 6px 12px;
  }

  /* Table Styles */

  /* Hover Info Styles for Mobile */
  .hover-info {
    right: auto;
    left: 0;
    top: -5px;
    transform: none;
    width: 150%;
    padding: 8px;
    font-size: 12px;
    z-index: 50;
  }
  .footer {
    font-size: 12px; /* Make the font size smaller for mobile */
    padding: 10px;
    margin: 10px;
  }

  .legal-disclaimer {
    /* Adjust styles for the legal disclaimer section */
    font-size: 12px; /* Make the font size smaller for mobile */
    padding: 10px;
    margin: 10px;
  }
}
